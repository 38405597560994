module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Playground Metaverse","short_name":"PLAYGROUND","description":"Parallel to the world we live, work and play in, the Playground Metaverse is infused with core elements from MMORPGs and coupled with stunning graphics.","start_url":"/","background_color":"#324b88","theme_color":"#324b88","display":"standalone","icon":"src/assets/favicon_io/favicon-32x32.png","icons":[{"src":"src/assets/favicon_io/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/assets/favicon_io/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/assets/favicon_io/andriod-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/favicon_io/andriod-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"144117ea52ddd30d8b3865d6c45b69b8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"431375975582342"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5RM6DGC","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
